<template>
  <section class="ticket-setting" :v-loading="loading">
    <PageTitle
      title="堂票參數設定"
      icon="chevron_left"
      style="padding-bottom: 24px"
      hideBtn
      @iconClick="$router.push({ name: 'Parameters' })"
    />
    <section v-if="useTicketReferPermission" class="section-block">
      <SectionTitle title="堂票轉贈設定" hideBtn />
      <div>
        <BaseElForm v-model="formData" label-position="left" label-width="250px">
          <BaseElFormItem label="開放轉贈堂票">
            <BaseElSwitch
              v-model="formData.clientGiveAllowed"
              data-cy="allow-order-multi-people-switch"
              class="switch-input"
              active-text="開啟"
              inactive-text="關閉"
              @change="updateTicketSetting"
            />
          </BaseElFormItem>
        </BaseElForm>
      </div>
    </section>
    <section v-if="useTicketRedeemPermission" class="section-block">
      <SectionTitle title="堂票客人核銷" hideBtn />
      <div>
        <BaseElForm v-model="formData" label-position="left" label-width="250px">
          <BaseElFormItem v-if="useStorePermission" label="客人核銷需選擇門市">
            <BaseElSwitch
              v-model="formData.clientUsageMustSelectBranch"
              data-cy="allow-order-multi-people-switch"
              class="switch-input"
              active-text="開啟"
              inactive-text="關閉"
              @change="updateTicketSetting"
            />
          </BaseElFormItem>
          <BaseElFormItem label="限制客人單次核銷上限">
            <BaseElSwitch
              v-model="formData.clientUsageTimesLimit"
              data-cy="allow-order-multi-people-switch"
              class="switch-input"
              active-text="開啟"
              inactive-text="關閉"
              @change="updateTicketSetting"
            />
          </BaseElFormItem>
          <BaseElFormItem
            v-if="formData.clientUsageTimesLimit"
            label="上限張數設定"
          >
            <BaseElInputNumber
              v-model="formData.clientUsageTimesMax"
              :min="1"
              :max="99999"
              @change="updateTicketSetting"
            />
          </BaseElFormItem>
        </BaseElForm>
      </div>
    </section>
    <section v-if="useTicketExpireNotificationPermission" class="section-block">
      <SectionTitle title="堂票設定" @edit="syncData" />
      <div>
        <BaseElForm v-model="formData" label-position="left" label-width="250px">
          <BaseElFormItem label="顯示名稱" prop="displayName">
            <div class="text-[#5E5E5E]">{{ formData.displayName }}</div>
          </BaseElFormItem>
          <BaseElFormItem label="顯示單位" prop="displayUnit">
            <div class="text-[#5E5E5E]">{{ formData.displayUnit }}</div>
          </BaseElFormItem>
          <BaseElFormItem
            label="到期通知發送天數"
            prop="expireNotificationBefore"
          >
            <div class="text-[#5E5E5E]">
              {{ formData.expireNotificationBefore }}天
            </div>
          </BaseElFormItem>
        </BaseElForm>
      </div>
    </section>
    <el-dialog
      title="堂票設定"
      :visible="expirySettingDialog"
      :close-on-click-modal="false"
      @close="expirySettingDialog = false"
    >
      <BaseElForm ref="formRef" :model="selectForm" :rules="formRules">
        <div class="flex items-center" style="gap: 8px; margin-bottom: 8px">
          <p class="font-bold"><span class="text-danger">* </span>顯示名稱</p>
          <el-tooltip placement="right">
            <div slot="content">
              您可以自行設定在客人端顯示的文字，至多 5 個字。
            </div>
            <span class="material-icons">help_outline</span>
          </el-tooltip>
        </div>
        <BaseElFormItem prop="displayName">
          <BaseElInput
            v-model="selectForm.displayName"
            clearable
            :maxlength="5"
            show-word-limit
          />
        </BaseElFormItem>
        <div class="flex items-center" style="gap: 8px; margin-bottom: 8px">
          <p class="font-bold"><span class="text-danger">* </span>顯示單位</p>
          <el-tooltip placement="right">
            <div slot="content">
              您可以自行設定堂票在客人端顯示的文字，至多 3個字。例如：份、組 等
            </div>
            <span class="material-icons">help_outline</span>
          </el-tooltip>
        </div>
        <BaseElFormItem prop="displayUnit">
          <BaseElInput
            v-model="selectForm.displayUnit"
            clearable
            :maxlength="3"
            show-word-limit
          />
        </BaseElFormItem>
        <div class="flex items-center" style="gap: 8px; margin-bottom: 8px">
          <p class="font-bold">
            <span class="text-danger">* </span>到期通知發送天數設定
          </p>
          <el-tooltip placement="right">
            <div slot="content">
              系統將於您在到期日前 X 天的中<br>
              午 12 點推播 Line 訊息提醒 舉<br>
              例：若設定到期日前 3 天通知假設<br>
              位客人的堂票是12/31 到期，系<br>
              統將於 12/28 的中午 12 點提醒
            </div>
            <span class="material-icons">help_outline</span>
          </el-tooltip>
        </div>
        <BaseElFormItem prop="expireNotificationBefore">
          <BaseElInput v-model="selectForm.expireNotificationBefore" clearable />
        </BaseElFormItem>
      </BaseElForm>

      <div slot="footer">
        <BaseElButton plain @click="expirySettingDialog = false">取消</BaseElButton>
        <BaseElButton
          type="primary"
          @click="confirmExpirySettingDialog"
        >
          確定
        </BaseElButton>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import { defineComponent, ref, reactive, computed, onMounted } from 'vue'
import store from '@/store'
import { FindTicketConfig, UpdateTicketConfig } from '@/api/ticket'
import { usePermissions } from '@/use/permissions'
import formUtils from '@/utils/form'
import { noEmptyRules } from '@/validation'

export default defineComponent({
  setup () {
    const formData = reactive({
      clientGiveAllowed: false,
      clientUsageMustSelectBranch: false,
      clientUsageTimesLimit: false,
      clientUsageTimesMax: 1,
      expireNotificationBefore: 14,
      displayName: '堂票',
      displayUnit: '份',
    })
    const selectForm = reactive({
      displayName: '',
      displayUnit: '',
      expireNotificationBefore: 0,
    })
    const formRef = ref(null)
    const formRules = reactive({
      displayName: [noEmptyRules()],
      displayUnit: [noEmptyRules()],
      expireNotificationBefore: [noEmptyRules()],
    })
    const loading = ref(false)
    const expirySettingDialog = ref(false)
    const shopId = computed(() => store.getters.shop)
    const { checkAction } = usePermissions()

    const useStorePermission = computed(() => {
      return checkAction('admin.branch.adminUseStore')
    })
    const useTicketReferPermission = computed(() => {
      return checkAction('admin.classTicketConfig.clientGive')
    })
    const useTicketRedeemPermission = computed(() => {
      return checkAction('admin.classTicketConfig.clientUsage')
    })

    const useTicketExpireNotificationPermission = computed(() => {
      return checkAction('admin.classTicketConfig.expireNotification')
    })

    const findTicketSetting = async () => {
      if (loading.value) return
      loading.value = true
      const [res, err] = await FindTicketConfig({ shopId: shopId.value })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      formData.clientUsageMustSelectBranch = res.clientUsageMustSelectBranch
      formData.clientUsageTimesLimit = res.clientUsageTimesLimit
      formData.clientGiveAllowed = res.clientGiveAllowed
      formData.clientUsageTimesMax = res.clientUsageTimesMax || 1
      formData.expireNotificationBefore = res.expireNotificationBefore || 14
      formData.displayName = res.displayName || '堂票'
      formData.displayUnit = res.displayUnit || '份'
    }

    const updateTicketSetting = async () => {
      if (loading.value) return
      const {
        clientUsageMustSelectBranch,
        clientUsageTimesLimit,
        clientUsageTimesMax,
        clientGiveAllowed,
      } = formData
      const {
        expireNotificationBefore,
        displayName,
        displayUnit,
      } = selectForm

      loading.value = true
      const [, err] = await UpdateTicketConfig({
        shopId: shopId.value,
        clientUsageMustSelectBranch,
        clientUsageTimesLimit,
        clientUsageTimesMax,
        clientGiveAllowed,
        expireNotificationBefore,
        displayName,
        displayUnit,
      })
      window.$message.success('更新成功！')
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      findTicketSetting()
    }

    const confirmExpirySettingDialog = async () => {
      const valid = await formUtils.checkForm(formRef.value)
      if (valid) {
        updateTicketSetting()
        expirySettingDialog.value = false
      }
    }
    const syncData = () => {
      Object.assign(selectForm, formData)
      expirySettingDialog.value = true
    }
    onMounted(() => {
      findTicketSetting()
    })
    return {
      formData,
      formRef,
      formRules,
      updateTicketSetting,
      loading,
      useStorePermission,
      useTicketReferPermission,
      useTicketRedeemPermission,
      expirySettingDialog,
      confirmExpirySettingDialog,
      useTicketExpireNotificationPermission,
      syncData,
      selectForm,
    }
  },
})
</script>
